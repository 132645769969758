/**
 * 利用者更新用ダイアログモジュール。
 * @module app/formDisplay/formDisplayDialog
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date',
    'dojo/date/locale',
    'dojo/dom-style',
    'dojo/text!./templates/formDisplayDialog.html',
    'dojo/topic',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/view/Loader',
    'idis/service/Requester',
    'app/config',
    'app/formDisplay/_formDisplayDialogBase',
    'app/formDisplay/displayType',
    'idis/util/DateUtils',
    'app/util/DateFormatter',
    // 以下、変数で受けないモジュール
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    'app/view/form/MunicipalityMultiSelectorNoPref',
    'app/view/form/OrganizationMultiSelector',
    'app/view/form/DisasterSelector',
    'app/view/form/EarthQuakeSelector',
    'app/formDisplay/damagetype/DamageGridTypeSelector'
], function(module, leaflet, declare, lang, date, locale, domStyle, template, topic, ACL,
    UserInfo, Loader, Requester, config, _formDisplayDialogBase, displayType, DateUtils, DateFormatter) {

    return declare(module.id.replace(/\//g, '.'), _formDisplayDialogBase,
    {
        // テンプレート文字列
        templateString: template,

        /**
         * 最新日時
         * デフォルトは現在日時の直近10分刻み時刻
         * @type {Date}
         */
        latestDateTime: null,

        /**
         * 災害発生日時
         */
         _disasterStartTimestamp: null,
         _disasterEndTimestamp: null,

        constructor: function() {
            this.latestDateTime = this.roundTime(new Date());

            // 最過去日時を設定
            var dateStr = locale.format(date.add(this.latestDateTime, 'day', -365), {
            	selector: 'date',
            	datePattern: 'yyyy/MM/dd'
            });
            this.oldestDateTime = new Date(dateStr + ' 00:00');
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },
    
        /**
         * フォームを初期化する。
         */
        initForm: function (object) {
            this._screenId = object.screenId;
            // ディスプレイ種別にモデルをセット
            this.displayType.set('store', displayType);
            // セレクトボックスのスタイル設定
            this.dateSelect.set('style', {width: '10em'});
            this.timeSelect.set('style', {width: '8em'});
            // 日付のセレクトボックスを生成する
            this.setDateSelect();
            // 時間のセレクトボックスを生成する
            this.setTimeSelect(this.latestDateTime);

            Requester.get('/api/formDisplay/detail/' + this._screenId)
            .then(lang.hitch(this,function(data) {
                var item = data;
                this.displayType.set('value', item.displayType);
                this.remarks.set('value', item.remarks);
                if (item.disasterId !== null) {
                    this.disasterId.set('value', item.disasterId);
                }
                if (item.municipalityCd !== null) {
                    this.municipalityCd.set('value', item.municipalityCd);
                }
                if (item.organizationCd !== null) {
                    this.organizationCd.set('value', item.organizationCd);
                }
                if (item.notifyDist !== null) {
                    this.notifyDist.set('value', item.notifyDist);
                }
                if (item.hldStatus !== null) {
                    this.hldStatus.set('value', item.hldStatus);
                }
                if (item.lifelineKindCd !== null) {
                    this.lifelineKindCd.set('value', item.lifelineKindCd);
                }
                if (item.notifyType !== null) {
                    this.notifyType.set('value', item.notifyType);
                }
                if (item.reportedStatus !== null) {
                    this.reportedStatus.set('value', item.reportedStatus);
                }
                if (item.shelterStatus !== null) {
                    this.shelterStatus.set('value', item.shelterStatus);
                }
                if (item.urgencyType !== null) {
                    this.urgencyType.set('value', item.urgencyType);
                }
                if (item.earthQuake !== null) {
                    this.earthQuake.set('value', item.earthQuake);
                }
                if (item.riverLevel !== null) {
                    this.riverLevel.set('value', item.riverLevel);
                }
                if (item.startTimestamp !== null) {
                    this.startTimestamp.set('value', item.startTimestamp);
                }
                if (item.endTimestamp !== null) {
                    this.endTimestamp.set('value', item.endTimestamp);
                }
                if (item.damageTypeList !== null) {
                    this.innerDamageGridTypeSelector.resetDamageType();
                    var damageTypeArr = item.damageTypeList.split(',');
                    this.innerDamageGridTypeSelector.setDamageType(damageTypeArr);
                }
                if (item.dateSelect !== null) {
                    this.dateSelect.set('value', item.dateSelect);
                }
                if (item.timeSelect !== null) {
                    this.timeSelect.set('value', item.timeSelect);
                }
            }));
        },

        /**
         * 更新する。
         */
        onSubmit: function() {
            try {
                console.debug('[更新]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if (!this.validate()) {
                    return false;
                }
                var value = this.form.get('value');
                value.screenId = this._screenId;
                value.damageTypeList = this._damageTypeList;
                this.emit('update', { value: value });
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(object) {
            this.reset();
            this.initForm(object);
            this.screenId.innerHTML = this._screenId;
            this.initSearchSelector(object.displayType);
            this.own(topic.subscribe(this.DAMAGE_GRID_TYPE_SELECTED, lang.hitch(this, function (data) {
                this.setDamageType(data);
            })));

            this.own(topic.subscribe(this.DAMAGE_GRID_TYPE_RELEASED, lang.hitch(this, function (data) {
                this.releaseDamageType(data);
            })));
        },

        /**
         * 災害選択が変更された場合の挙動
         */
        onDisasterChanged: function(disasterId) {
            if (disasterId.value) {
                domStyle.set(this.disasterTimeStampArea, 'display', '');
                var promise = Requester.get('/api/disasters/' + disasterId.value);
                Loader.wait(promise).then(lang.hitch(this, function (data) {
                    if (data.startTimestamp !== null && data.endTimestamp !== null) {
                        this.disasterTimeStamplabel.innerText = 
                        DateUtils.format(data.startTimestamp) + ' ～ ' + DateUtils.format(data.endTimestamp);
                    }else if (data.startTimestamp !== null && data.endTimestamp === null) {
                        this.disasterTimeStamplabel.innerText = 
                        DateUtils.format(data.startTimestamp) + ' ～ 現在発生中';
                    }
                }));
            } else {
                domStyle.set(this.disasterTimeStampArea, 'display', 'none');
            }
        },

        /** 初期表示：検索条件の表示・非表示を管理する*/
        initSearchSelector: function(displayType){
            this.resetDisplaySearch();
            if (displayType === 1){
                domStyle.set(this.earthQuakeArea, 'display', '');
            } else if (displayType === 2) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (displayType === 3) {
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (displayType === 4) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (displayType === 5) {
                document.getElementById('organLabel').innerText = '発信所属：';
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.notifyTypeArea, 'display', '');
                domStyle.set(this.notifyDistArea, 'display', '');
            } else if (displayType === 6) {
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (displayType === 7) {
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.lifelineArea, 'display', '');
            } else if (displayType === 8) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.hldStatusArea, 'display', '');
                domStyle.set(this.severityArea, 'display', '');
                domStyle.set(this.reportedStatusArea, 'display', '');
                domStyle.set(this.damageTypeArea, 'display', '');
            } else if (displayType === 9) {
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.hldStatusArea, 'display', '');
                domStyle.set(this.severityArea, 'display', '');
                domStyle.set(this.reportedStatusArea, 'display', '');
            } else if (displayType === 10 || displayType === 11) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.congestionArea, 'display', '');
            } else if (displayType === 12) {
                // domStyle.set(this.disasterArea, 'display', '');
                // domStyle.set(this.startTimestampArea, 'display', '');
                // domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.riverLevelArea, 'display', '');
                domStyle.set(this.riverTimestampArea, 'display', '');
            }
        },

        resetDisplaySearch: function(){
            document.getElementById('organLabel').innerText = '組織選択：';
            domStyle.set(this.organizationArea, 'display', 'none');
            domStyle.set(this.municipalityArea, 'display', 'none');
            domStyle.set(this.disasterArea, 'display', 'none');
            domStyle.set(this.startTimestampArea, 'display', 'none');
            domStyle.set(this.endTimestampArea, 'display', 'none');
            domStyle.set(this.congestionArea, 'display', 'none');
            domStyle.set(this.hldStatusArea, 'display', 'none');
            domStyle.set(this.severityArea, 'display', 'none');
            domStyle.set(this.lifelineArea, 'display', 'none');
            domStyle.set(this.reportedStatusArea, 'display', 'none');
            domStyle.set(this.notifyTypeArea, 'display', 'none');
            domStyle.set(this.damageTypeArea, 'display', 'none');
            domStyle.set(this.notifyDistArea, 'display', 'none');
            domStyle.set(this.riverLevelArea, 'display', 'none');
            domStyle.set(this.earthQuakeArea, 'display', 'none');
            domStyle.set(this.riverTimestampArea, 'display', 'none');
        },

        // ディスプレイ種別が変更になる場合に呼ぶ
        onDisplayTypeChanged: function(){
            this.resetDisplaySearch();
            console.log(this.displayType.value);
            if (this.displayType.value === 1){
                domStyle.set(this.earthQuakeArea, 'display', '');
            } else if (this.displayType.value === 2) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (this.displayType.value === 3) {
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (this.displayType.value === 4) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (this.displayType.value === 5) {
                document.getElementById('organLabel').innerText = '発信所属：';
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.notifyTypeArea, 'display', '');
                domStyle.set(this.notifyDistArea, 'display', '');
            } else if (this.displayType.value === 6) {
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (this.displayType.value === 7) {
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.lifelineArea, 'display', '');
            } else if (this.displayType.value === 8) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.hldStatusArea, 'display', '');
                domStyle.set(this.severityArea, 'display', '');
                domStyle.set(this.reportedStatusArea, 'display', '');
                domStyle.set(this.damageTypeArea, 'display', '');
            } else if (this.displayType.value === 9) {
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.hldStatusArea, 'display', '');
                domStyle.set(this.severityArea, 'display', '');
                domStyle.set(this.reportedStatusArea, 'display', '');
            } else if (this.displayType.value === 10 || this.displayType.value === 11) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.congestionArea, 'display', '');
            } else if (this.displayType.value === 12) {
                // domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.riverLevelArea, 'display', '');
                domStyle.set(this.riverTimestampArea, 'display', '');
                // domStyle.set(this.startTimestampArea, 'display', '');
                // domStyle.set(this.endTimestampArea, 'display', '');
            }
        },

        /**
         * 直近の10分刻み時刻に丸める
         * 例えば、10時03分の場合は10時00分、10時13分の場合は10時10分に丸められる
         * @param {Date}
         * @returns {Date} 丸めた日時
         */         
        roundTime: function(dt) {
            // 分を取得し、10で割り切れなければ、そのあまりでminuteをマイナスする
            var minutes = dt.getMinutes();
            var mod = minutes % 10;
            if (mod !== 0) {
                return date.add(dt, 'minute', -mod);
            }
            return dt;
        },

        /**
         * 日付のセレクトボックスを生成する
         */
         setDateSelect: function() {
        	// 最新日時から10日前までの日付を算出し、セレクトボックスを生成
            var currentDate = this.latestDateTime;
            var counter = 0;
            this._dateOptions = [];
			this._dateOptions.push({ value: '', label: '　' });
            // 選択値。最後に追加されたアクティブなオプション時刻がデフォルト選択値。
            var defaultDate = null;

            // 1年前まで繰り返す
            while (counter < 366) {
            	// セレクトボックスの値
                var dateValue = locale.format(currentDate, {
                	selector: 'date',
                	datePattern: 'yyyy/MM/dd'
                });
                // セレクトボックスのラベル 「yyyy年MM月dd日」
                var dateLabel = DateFormatter.jpDate(currentDate, true);
				this._dateOptions.push({ value: dateValue, label: dateLabel });
                defaultDate = dateValue;

                // 1日前に更新
                currentDate = date.add(currentDate, 'day', -1);
                // カウンターを増やす
            	counter++;
            }
            // セレクトボックスを設定
            this.dateSelect.set('options', this._dateOptions);
            this.dateSelect.startup();

            // デフォルト値の設定
        	this.timeSelect.set('value', defaultDate);
        },

        /**
         * 時間のセレクトボックスを生成する
         * @param {Date} 最新時刻。指定されていればその時間以降の選択をdisabledにする。
         */
        setTimeSelect: function() {

        	// ループの開始時刻（使いたいのは時刻なので日付は何でも良い。0時0分）
        	var currentDate = new Date(1970, 1, 1, 0, 0);
        	var counter = 0;
        	this._timeOptions = [];
			this._timeOptions.push({ value: '', label: '　', disabled: false });
        	// 選択値。最後に追加されたアクティブなオプション時刻がデフォルト選択値。
        	// var defaultTime = null;
        	// 00時00分から23時50分まで生成するので、ループは144回
        	while (counter < 144) {
        		// セレクトボックスの値
        		var timeValue = locale.format(currentDate, {
        			selector: 'time',
        			timePattern: 'HH:mm'
        		});
        		// セレクトボックスのラベル
        		var timeLabel = locale.format(currentDate, {
        			selector: 'time',
        			// 24時間指定
        			timePattern: 'HH時mm分'
        		});
				this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
				// defaultTime = timeValue;
        		/*if (!datetime) {
            		// 10分モードの場合
            		this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
            		defaultTime = timeValue;
            		// }
        		} else {
        			// 最新観測日の場合は、時刻を越えている場合は選択不可
        			if (date.compare(datetime, currentDate, 'time') !== -1) {
        				this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
        				defaultTime = timeValue;
        			} else {
        				this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: true });
        			}
        		}*/

        		// 10分後に更新
        		currentDate = date.add(currentDate, 'minute', 10);
                // カウンターを増やす
            	counter++;
        	}

        	// セレクトボックスを設定
            this.timeSelect.set('options', this._timeOptions);
            this.timeSelect.startup();

            // デフォルト値の設定
        	// this.timeSelect.set('value', defaultTime);
        }
    });
});
