/**
 * ログイン画面用モジュール
 * @module app/login/view/LoginPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/io-query',
    'dojo/text!./templates/LoginPage.html',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/view/page/_PageBase',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'idis/view/form/Button',
    './PasswordResetDialog'
], function(module, declare, lang, domStyle, ioQuery, template, Locator, Router, _PageBase, Requester) {
    /**
     * ログイン画面用ウィジェット
     * @class LoginPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/login/view/LoginPage~LoginPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--login',

        buildRendering: function() {
            this.inherited(arguments);
            // 戻り先を設定（現在のURLのpath部分）
            var backPath = Locator.getHref().split('?')[0];
            this.backPathInput.set('value', backPath);
            // URLにログイン失敗フラグが含まれている場合はエラー・メッセージを表示する
            if (Locator.getQuery().f) {
                domStyle.set(this.errorMessageNode, 'display', '');
            }
            // 訓練フラグ
            this.trainingFlgInput.set('value', false);

            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.innerResetDialog);
        },
        initPage: function () {
            // パスワードリセットダイアログを初期化
            this.initResetDialog();
        },
        changeMode: function(evt) {
            evt.preventDefault();
            // 訓練モードログイン画面に遷移
            // Router.moveTo('login/training');
            // 訓練モードアプリ側のログイン画面に遷移
            // location.href = location.origin + '/training/';
            Requester.get('/data/master/config.json')
            .then(lang.hitch(this, function(data) {
                location.href = data.linkTrainUrl ? data.linkTrainUrl : data.linkurl;
            }), function(error) {
                console.error('URL取得に失敗しました。', error);
                location.href = location.origin;
            });
        },

        /**
         * フォーム投稿時に呼び出される。
         * @returns {boolean} formイベントをキャンセルする場合は明示的にfalseを返す
         */
        onSubmit: function() {
            // 入力チェックが成功した場合だけ通す
            if (!this.form.validate()) {
                return false;
            }
            // 開いているのがログイン画面以外のURLなら戻り先情報として渡す
            var query = Locator.getQuery();
            if (query.p !== 'login') {
                // 失敗フラグがあれば除去
                delete query.f;
                // hiddenフィールドへ設定
                this.backQueryInput.set('value', ioQuery.objectToQuery(query));
            }
        },
        showResetDialog: function() {
            console.debug('[パスワードリセット]リンクがクリックされました。');
            this.innerResetDialog.initDialog();
            this.resetDialog.show();
        },
        download: function() {
            var url = '/data/out/material/manual.pptx';
            window.open(url, '大阪市防災情報システム操作マニュアル');
        }
    });
});
