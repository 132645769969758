define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/date/locale',
	'idis/view/grid/IdisGrid',
	'idis/view/grid/helper',
	'dgrid/Selection',
	'dgrid/Selector',
	'dgrid/Keyboard'
], function (module, declare, lang, locale, IdisGrid, helper, Selection, Selector, Keyboard) {

	// var DAMAGE_ATC_FILE_THUMBNAIL_SUFFIX = '_thumbnail';

	return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selection, Selector, Keyboard], {

		// ルート要素に付与されるCSS
		baseClass: 'idis-Page idis-Page--damage',


		/**
		 * 各列の定義
		 * @type {Object[]}
		 */

		columns: [
			// ボタン
			helper.buttonColumn('detail', '詳細'),
			// {field: 'reportName', label: '報告名', sortable: false},
			helper.column('attention', '要対応', {
				className: function (item) {
					if (!item) { return ""; }
					return item.attention === 1 && item.publicFlg === '9' ? "is-attention-private" : item.attention === 1 ? "is-attention" : "";
				},
				formatter: function (item) {
					return item === 1 ? "*" : "";
				}
			}),
			helper.column('reportStatus', '報告状況', {
				// className: function (item) {
				// 	if (!item) { return ""; }
				// 	return item.publicFlg !== "0" && item.publicFlg !== "9" && item.reportStatus === "41" ? "is-attention" : "";
				// },
				// formatMap: { '11': '未確定', '12': '速報（確認待ち）', '31': '速報（所属確認済）', '32': '速報（区本部確認待ち）', '41': '確定報' }
				formatter: lang.hitch(this, function (value, item) {
					console.log(item);
					if (item.reportStatus === '11') {
						return '未確定';
					}
					if (item.reportStatus === '12') {
						return '速報（確認待ち）';
					}
					if (item.reportStatus === '31') {
						return '速報（所属確認済）';
					}
					if (item.reportStatus === '32') {
						return '速報（区本部確認待ち）';
					}
					if (item.reportStatus === '41') {
						if (item.publicFlg === "9") {
							return "非公開報";
						}
						if (item.publicFlg !== "0") {
							return "公開報";
						}
						return '確定報';
					}
				})
			}),
			{
				field: 'admNum', label: '管理番号',
				formatter: lang.hitch(this, function (value, item) {
					if (!item) {
						return;
					}
					return item.admNum;
				})
			},
			helper.column('seqNum', '連番', {
				formatter: function (val) {
					return "第" + val + "報";
				}
			}),
			helper.column('damageTypeNameList', '被害の\n種類', { sortable: false }),

			helper.column('damageTimestamp', '発生日時', {
				formatter: function (val) {
					if (val) {
						var date = locale.parse(val, {
							datePattern: 'yyyy-MM-dd',
							timePattern: 'HH:mm:ss'
						});
						return locale.format(date, {
							datePattern: 'yyyy-MM-dd',
							timePattern: 'HH:mm'
						});
					} else {
						return '';
					}
				}
			}),
			helper.column('damageAddress', '発生場所'),

			helper.column('damageScale', '被害規模', {
				formatMap: {
					'0': '軽微・被害なし',
					'1': '小',
					'2': '中',
					'3': '大',
					'9': '-'
				},
				sortable: false,
			}),
			helper.column('urgencyType', '深刻度',
				{
					className: lang.hitch(this, function (item) {
						// ヘッダーの場合はclassNameを返さない
						if (!item) {
							return;
						}
						if (item.urgencyType === '1') {
							return 'low-priority';
						} else if (item.urgencyType === '2') {
							return 'normal-priority';
						} else if (item.urgencyType === '3') {
							return 'high-priority';
						}
					}),
					formatMap: {
						'0': '軽微・被害なし',
						'1': '低',
						'2': '中',
						'3': '高',
						'9': '-'
					},
					sortable: false,
				}),

			helper.column('damageHeader', '被害概要\n/事案概要', {
				sortable: false
			}),
			helper.column('damageBody', '被害等/現場措置\n(復旧見込みを含む)', {
				sortable: false
			}),
			helper.column('shulterName', '施設名称\n(庁舎)', {
				sortable: false
			}),
			helper.column('trafficStatus', '被害による\n通行状況', {
				sortable: false
			}),

			helper.column('estimateRecoveryTimestamp', '対応完了\n見込み時間\n/復旧日時', {
				formatter: function (val) {
					if (val) {
						var date = locale.parse(val, {
							datePattern: 'yyyy-MM-dd',
							timePattern: 'HH:mm:ss'
						});
						return locale.format(date, {
							datePattern: 'yyyy-MM-dd',
							timePattern: 'HH:mm'
						});
					} else {
						return '';
					}
				},

				sortable: false
			}),
			helper.column('hldStatus', '措置\n完了', {
				formatMap: {
					'0': '',
					'1': '',
					'2': '',
					'3': '〇'
				}
			}),


			helper.column('updTimestamp', '更新日時', {
				formatter: function (val) {
					if (val) {
						var date = locale.parse(val, {
							datePattern: 'yyyy-MM-dd',
							timePattern: 'HH:mm:ss'
						});
						return locale.format(date, {
							datePattern: 'yyyy-MM-dd',
							timePattern: 'HH:mm'
						});
					} else {
						return '';
					}
				}
			}),

		],

		// renderRow: function (item) {
		// 	var div = this.inherited(arguments);
		// 	topic.publish(module.id + '::drawIcon', item);
		// 	return div;
		// }

	});
});
