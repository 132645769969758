/**
 * ポータル配信情報テンプレート登録用ダイアログモジュール。
 * @module app/prefsitesending/PrefSiteSendingTemplateRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/PrefSiteSendingTemplateRegisterDialog.html',
    './_PrefSiteSendingTemplateDialogBase',
    'idis/model/UserInfo',
    '../config',
    // 以下、変数で受けないモジュール
    'app/view/form/MunicipalitySelector',
    'app/view/form/OrganizationSelector'
// ], function(module, declare, template, _PrefSiteSendingTemplateDialogBase) {
], function(module, declare, template, _PrefSiteSendingTemplateDialogBase, UserInfo, config) {
    /**
     * ポータル配信情報テンプレート登録用ダイアログ
     * @class PrefSiteSendingTemplateDialog
     * @extends module:./_PrefSiteSendingTemplateDialogBase~_PrefSiteSendingTemplateDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _PrefSiteSendingTemplateDialogBase, {
        // テンプレート文字列
        templateString: template,

        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * ダイアログの初期化
         */
        initDialog: function() {
            this.form.reset();
            // this.municipalityCd.set('value', UserInfo.getMunicipalityCd() || config.municInfo.prefMunicCd);
            this.municipalityCd.set('value', config.municInfo.cityMunicCd);
            // this.sendingSection.set(
            //     'value', UserInfo.getLowestOrganizationCd()[0] + UserInfo.getLowestOrganizationCd());
        },

        /**
         * 新規登録
         */
        onSubmit: function(){
            try {
                console.debug('[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                this.emit('register', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        }
    });
});
