/**
* 被害状況 概況画面用モジュール。
* @module app/damege/DamageReportAdminPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/DamageReportAdminPage.html',
    'dojo/dom-style',
    'dojo/topic',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/model/DisasterInfo',
    'idis/view/dialog/DialogChain',
    'app/config',
    '../_DamageReportPageBase',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    './DamageReportAdminGrid',
    'app/damage/damageForm/DamageReportFormDialog',
    'app/view/form/RegionSelector',
    'app/damage/damageFileUpload/DamageFileUploadDialog',
    'app/damage/damageFireFileUpload/DamageFireFileUploadDialog'
], function (module, declare, lang, template, domStyle, topic,
    Router, IdisRest, UserInfo, UserType, DisasterInfo, DialogChain,
    config, _DamageReportPageBase) {

    var damageFilterStore = {};

    /**
    * 被害報画面。
    * @class DamageReportAdminPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _DamageReportPageBase,
        /** @lends module:app/damege/damageReportAdmin/DamageReportAdminPage~DamageReportAdminPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--damage',

            /**
            * データ格納用オブジェクト
            * @type {module:dstore/Store}
            */
            store: null,

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            constructor: function () {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'municipalityCd',
                    target: '/api/damageReports/overview'
                });
                this.chain = DialogChain.get(this);

                console.debug('現在のログインユーザID：' + UserInfo.getId());
                console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
            },

            startup: function () {
                this.inherited(arguments);

                // グリッドを初期化する
                this.initGrid();

                // 大阪市：画面自動更新用のタイマーを設定(5分毎)
                this.timer = setInterval(lang.hitch(this, function () {
                    this.updateGridQuery();
                }), 5 * 60 * 1000);

                // 消防局または、管理ユーザのみ消防局一括登録可能
                if (UserInfo.getOrganization().deptCd === config.dept.fireDepartmentDeptCd || UserInfo.isSysAdmin()) {
                    this.fireFileUploadButton.set('style', { display: '' });
                } else {
                    this.fireFileUploadButton.set('style', { display: 'none' });
                }

            },

            /**
             * widgetが居なくなる時に呼ばれる
             * 定期処理を止める
             */
            destroy: function () {
                this.inherited(arguments);
                clearInterval(this.timer);
            },

            /**
            * グリッドを初期化する。
            */
            initGrid: function () {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.damageReportAdminGrid.on('listButtonClick', lang.hitch(this, function (evt) {
                    this.onListButtonClick(evt);
                }));

                // 保管した検索条件をセットする
                this.setFilterData();

                this.updateGridQuery();

            },


            /**
            * 検索
            */
            onSubmit: function () {
                try {
                    if (this.form.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery();
                    } else {
                        console.debug('エラーあり');
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
            *検索パラメーターの設定
            */
            updateGridQuery: function () {
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();
                var value = this.form.get('value');

                // 災害ID
                filter = filter.eq('disasterId', this._disasterId);

                // 集計日時
                if (value.aggregateDateTime) {
                    var aggregateDateTime = new Date(value.aggregateDateTime).getTime();
                    filter = filter.eq('aggregateDateTime', aggregateDateTime);
                }

                // すべての市区町村を表示：
                if (value.showNoDamageMunicFlg.length !== 0) {
                    var showNoDamageMunicFlg = '1';
                    filter = filter.eq('showNoDamageMunicFlg', showNoDamageMunicFlg);
                } else if (UserInfo.getUserType() === UserType.MUNICIPALITY &&
                    UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd) {
                    // 政令指定都市ユーザだった場合、デフォルト表示は市内各区とする
                    filter = filter.eq('municipalityCd', config.municInfo.cityMunicCd);
                } else if (UserInfo.getUserType() === UserType.REGION) {
                    // 振興局ユーザだった場合、デフォルト表示は市内各区とする
                    filter = filter.eq('regionCd', UserInfo.getRegionCd());
                }
                filter = filter.eq('isFixedReport', this.isFixedReport.checked ? '1' : '0');
                filter = filter.eq('isPublicReport', this.isPublicReport.checked ? '1' : '0');
                // システム管理者かどうか（可視範囲が変わる)
                filter = filter.eq('isSysAdm', UserInfo.isSysAdmin());

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.damageReportAdminGrid.set('collection', collection);

                this.setFilterStore();
            },

            /**
             * 検索条件を保管する
             */
            setFilterStore: function () {
                //初期化する
                damageFilterStore = {};
                var value = this.form.get('value');

                // 集計日時
                if (value.aggregateDateTime) {
                    damageFilterStore.aggregateDateTime = value.aggregateDateTime;
                }

                // すべての市町村表示
                if (value.showNoDamageMunicFlg.length > 0) {
                    damageFilterStore.showNoDamageMunicFlg = 'checked';
                }
            },

            /**
             * 保管した検索条件をフォームにセットする
             */
            setFilterData: function () {
                // 集計日時
                if (damageFilterStore.aggregateDateTime) {
                    this.aggregateDateTime.set('value', damageFilterStore.aggregateDateTime);
                }
                // すべての市町村表示
                if (damageFilterStore.showNoDamageMunicFlg) {
                    this.showNoDamageMunicFlg.set('checked', 'checked');
                }
            },

            /**
             * 新規登録画面を表示
             */
            showRegisterPage: function () {
                // 新規登録画面へ遷移
                this._removeEvents();
                this.setFilterStore();
                Router.moveTo('report/register');

            },

            /**
            *一覧ボタン押下時
            */
            onListButtonClick: function (evt) {
                // 被害状況画面へ遷移
                this._removeEvents();
                this.setFilterStore();
                Router.moveTo('report', { municipalityCd: evt.item.municipalityCd });
            },

            /**
             * 帳票出力
             */
            showDamageForm: function () {
                this.damageFormDialog.show();
            },
            /**
             * ファイル取り込み
             */
            showFileUploadDialog: function () {
                this.fileUploadDialog.show();
            },
            /**
             * 消防局一括登録
             */
            showFireFileUploadDialog: function () {
                this.fireFileUploadDialog.show();
            },

            onChangeFixedReport: function () {
                if (this.isFixedReport.checked) {
                    this.isPublicReport.set('checked', false);
                }

            },
            onChangePublicReport: function () {
                if (this.isPublicReport.checked) {
                    this.isFixedReport.set('checked', false);
                }
            }


        });
});
