/**
 * ポータル配信情報管理面用モジュール。
 * @module app/emergency/PrefSiteSendingPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/PrefSiteSendingPage.html',
    'idis/view/page/_PageBase',
    'dojo/dom-style',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/consts/ACL',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    './PrefSiteSendingGrid',
    './PrefSiteSendingRegisterDialog',
    './PrefSiteSendingDetailDialog',
    'app/view/form/CustomizableMunicipalitySelector'
], function(module, declare, lang, Router, template, _PageBase, 
        domStyle, UserInfo, IdisRest, DialogChain, Loader, ACL) {
    /**
     * 検索条件保存用オブジェクト
     * @type {Object}
     */
    var PrefSiteSendingFilterStore = {};

    /**
     * ポータル配信情報管理画面
     * @class PrefSiteSendingPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/emergency/PrefSiteSendingPage~PrefSiteSendingPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--prefsitesending',

        /**
         * データ格納用オブジェクト
         */
        store: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'prefSiteSendingId',
                target: '/api/prefsitesending'
            }).sort({ property: 'updTimestamp', descending: true });

            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);

            // ベースクラスのコンストラクタを呼ぶ
            this.inherited(arguments);
            console.debug('現在のログインユーザID：' + UserInfo.getId());
        },

        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.registerDialog);
            this.own(this.detailDialog);
        },

        startup: function() {
            this.inherited(arguments);
            this.initGrid();
            this.initDialog();
            this.setButtonStatus();
        },

        /**
         * グリッドの初期化
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailDialog(evt.item);
            }));

            // ページ遷移前の検索条件をロード
            this.loadFilterValue();

            this.updateGridQuery();
        },

        /**
         * ダイアログの初期化
         */
        initDialog: function() {
        	this.initRegisterDialog();
        	this.initDetailDialog();
        },

        /**
         * ボタンの表示非表示
         */
        setButtonStatus: function(){
            var role = UserInfo.getRoleCd();
            if (role === ACL.OTHER_USER) { // TODO 要確認。とりあえずその他ユーザを除外。
                domStyle.set(this.registerButton.domNode, 'display', 'none');
                domStyle.set(this.templateButton.domNode, 'display', 'none');
            }
        },

        /**
         * 新規登録ダイアログの初期化
         */
        initRegisterDialog: function() {
            var dialog = this.registerDialog;
            var page = dialog.getChildren()[0];

            // 登録画面のregisterイベントを受ける
            page.on('register', lang.hitch(this, function(evt) {
                console.debug('registerイベント');

                // 確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    var sendData = evt.value;
                    var attachFileList = sendData.attachFileList;

                    // 添付ファイルIDをセット
                    if(attachFileList.length > 0) {
                        var attachFileIds = [];
                        for(var i = 0; i < attachFileList.length; i++) {
                            attachFileIds.push(parseInt(attachFileList[i].emergencyNewsAtcFileId, 10));
                        }
                        sendData.attachFileIds = attachFileIds;
                    } else {
                        sendData.attachFileIds = [];
                    }

                    delete sendData.attachFile;
                    delete sendData.attachFileList;

                    console.log(sendData);

                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(sendData)).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        if(err.response.data) {
                            chain.infoError(err);
                        }
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細ダイアログの初期化
         */
        initDetailDialog: function() {
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];

            // 詳細画面のremoveイベントを受ける
            page.on('remove', lang.hitch(this, function(evt) {
                console.debug('removeイベント');

                var sendData = evt.value;
                this.chain.confirmDel(function(chain) {
                    var promise = this.store.remove(
                        sendData.prefSiteSendingId + 
                        '?version=' + sendData.version);
                    Loader.wait(promise).then(lang.hitch(this, function() {
                        // 詳細ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 一覧を更新する
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 詳細画面のupdateイベントを受ける
            page.on('update', lang.hitch(this, function(evt) {
                console.debug('updateイベント');

                // 更新確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    var sendData = evt.value;
                    var attachFileList = sendData.attachFileList;

                    // 添付ファイルIDをセット
                    if(attachFileList.length > 0) {
                        var attachFileIds = [];
                        for(var i = 0; i < attachFileList.length; i++) {
                            attachFileIds.push(parseInt(attachFileList[i].emergencyNewsAtcFileId, 10));
                        }
                        sendData.attachFileIds = attachFileIds;
                    } else {
                        sendData.attachFileIds = [];
                    }

                    delete sendData.attachFile;
                    delete sendData.attachFileList;

                    // 更新処理と共にローディング表示
                    Loader.wait(this.store.put(sendData)).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 更新ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * グリッドの更新
         */
        updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            // 対象地域
            if (value.municipalityCd && value.municipalityCd !== '') {
                filter = filter.eq('municipalityCd', value.municipalityCd);
            }

            // 所轄組織
            // if (value.sendingSection && value.sendingSection !== '') {
            //     var prefix = value.sendingSection[0];
            //     if (prefix === 'D'){
            //         filter = filter.eq('deptCd', value.sendingSection.slice(1));
            //     }else if (prefix === 'S'){
            //         filter = filter.eq('sectCd', value.sendingSection.slice(1));
            //     }else if (prefix === 'U'){
            //         filter = filter.eq('unitCd', value.sendingSection.slice(1));
            //     }
            // }

            // レベル
            if (value.urgencyType && value.urgencyType !== '') {
                filter = filter.eq('urgencyType', value.urgencyType);
            }

            // 掲載開始日時FROM
            if (value.pubStartTimestamp) {
                var pubStartTimestamp = new Date(value.pubStartTimestamp).getTime();
                filter = filter.eq('pubStartTimestamp', pubStartTimestamp);
            }

            // 掲載開始日時TO
            if (value.pubEndTimestamp) {
                var pubEndTimestamp = new Date(value.pubEndTimestamp).getTime();
                filter = filter.eq('pubEndTimestamp', pubEndTimestamp);
            }

            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        /**
        * ポータル配信情報の検索
        */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 新規登録ダイアログの表示
         */
        showRegisterDialog: function() {
            this.innerRegisterDialog.initDialog();
            this.registerDialog.show();
        },

        /**
         * 詳細ダイアログの表示
         */
        showDetailDialog: function(object) {
            this.innerDetailDialog.initDialog(object);
            this.detailDialog.show();
        },

        /**
         * テンプレート管理画面へ遷移
         */
        showTemplatePage: function() {
            // 検索条件の保存
            this.saveFilterValue();
            // テンプレート管理画面へ遷移
            Router.moveTo('prefsitesending/template');
        },

        /**
         * 検索条件の保存
         */
        saveFilterValue: function() {
            PrefSiteSendingFilterStore = {};
            var value = this.form.get('value');

            // 市町村
            if (value.municipalityCd !== '') {
                PrefSiteSendingFilterStore.municipalityCd = value.municipalityCd;
            }

            // 掲載日時
            if (value.pubStartTimestamp) {
                PrefSiteSendingFilterStore.pubStartTimestamp = value.pubStartTimestamp;
            }

            // 掲載期限
            if (value.pubEndTimestamp) {
                PrefSiteSendingFilterStore.pubEndTimestamp = value.pubEndTimestamp;
            }
        },

        /**
         * 検索条件のロード
         */
        loadFilterValue: function() {
            this.form.set('value', PrefSiteSendingFilterStore);
        }
    });
});
