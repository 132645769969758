/**
 * ユーザの新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/user/_UserDialogBase
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, leaflet, declare, lang, domStyle, ACL, UserInfo, _PageBase, InfoDialog, config) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {
        /**
         * ユーザID
         */
        _userId: null,

        /**
         * 緯度経度
         */
        _lat: null,
        _lng: null,

        /**
         * 住所
         */
         _address: null,

        /**
         * 上位組織を登録、更新する認可の有無
         */
         _authorized: null,

		 _acceptableUser:[ACL.AID_USER,
			ACL.VPD_USER,
			ACL.WEL_USER,
			ACL.FAC_USER,
			ACL.SEW_USER,
			ACL.ROA_USER],

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var formData = this.form.get('value');

            // accountType selection
            if (formData.roleCd === '') {
                InfoDialog.show('アカウント種類が選択されていません');
                return false;
            }
            //userIdが半角英数字かチェック
            if (!/^[0-9a-zA-Z]*$/.test(formData.userId)) {
                InfoDialog.show('入力チェックエラー', 'ユーザーIDは半角英数字で入力してください');
                return false;
            }
            // 市町村コードと振興局コードチェック
            switch (formData.roleCd.substr(0,3)) {
            case 'R01':
                // 県ユーザ
                // 何もチェックしない
                break;
            /*case 'R02':
                // 振興局ユーザ処理
                // 振興局必須チェック
                if (formData.regionCd === '') {
                    InfoDialog.show('振興局が選択されていません');
                    return false;
                }
                break;
            case 'R03':
                // 市町村ユーザ処理
                // 市町村必須チェック
                if (formData.municipalityCd === '') {
                    InfoDialog.show('市/行政区が選択されていません');
                    return false;
                }
                break;
            case 'R04':
                // 関係機関ユーザ処理
                // 何もチェックしない
                break;*/
            default:
                break;
            }

            // organization
            if (formData.organization === '') {
                InfoDialog.show('入力チェック', '組織が選択されていません');
                return false;
            }
			var role = UserInfo.getRoleCd();
			if (role !== ACL.ADMIN_USER) {
				if (UserInfo.getOrganization().sectCd !== null){
				   this._authorized = formData.organization.substr(0,1) === 'D' ?  '1' :
									  '0';
				}else if (UserInfo.getOrganization().unitCd !== null){
						 this._authorized = formData.organization.substr(0,1) === 'D' ?  '1' :
											formData.organization.substr(0,1) === 'S' ?  '1' :
											'0';
				}
				if (this._authorized === '1'){
					InfoDialog.show('入力チェック', '所属よりも上位の組織は選択できません');
					return false;
				}
			}
            if(role !== ACL.ADMIN_USER && formData.roleCd === ACL.ADMIN_USER){
                InfoDialog.show('入力チェック', 'システム管理者は選択できません');
                return false;
            }else if(!(role === ACL.ADMIN_USER || role === ACL.SYSMGR_USER) && formData.roleCd === ACL.SYSMGR_USER){
                InfoDialog.show('入力チェック', '全体管理者は選択できません');
                return false;
            }else if(!(role === ACL.ADMIN_USER || role === ACL.SYSMGR_USER || role === ACL.SHMGR_USER) &&
                formData.roleCd === ACL.SHMGR_USER){
                InfoDialog.show('入力チェック', '市本部管理者は選択できません');
                return false;
            }
            // account name
            if (formData.userId === '') {
                InfoDialog.show('入力チェック', 'ユーザIDが入力されていません');
                return false;
            }
            if (formData.userName === '') {
                InfoDialog.show('入力チェック', 'ユーザ名が入力されていません');
                return false;
            }
            // password
            if (this.title.innerHTML === '利用者情報新規登録' && formData.userPassword === '') {
                InfoDialog.show('入力チェック', 'パスワードが入力されていません');
                return false;
            }
            if (formData.userPassword !== formData.passwordConfirm) {
                InfoDialog.show('入力チェック', '入力されたパスワードが一致しません');
                return false;
            }
            if (formData.userPassword !== '' && !this.isValidPassword(formData.userPassword)) {
                InfoDialog.show('入力チェック', '入力されたパスワードが規則を満たしません。半角の英数および記号で８文字以上、２０文字以内で入力ください');
                return false;
            }
            // 電話番号が半角数字かチェック
            if (!/^[0-9a-zA-Z-]*$/.test(formData.representativePhoneNumber)) {
                InfoDialog.show('入力チェックエラー', '電話番号は半角で入力してください');
                return false;
            }
            // 庁内メールアドレスが半角英数字記号かチェック
            if (formData.officialMailAddress &&
                !/^[\x20-\x7e]*$/.test(formData.officialMailAddress.trim())) {
                InfoDialog.show('入力チェックエラー', '庁内メールアドレスは半角英数字記号で入力してください');
                return false;
            }

            // 個人用メールアドレスが半角英数字記号かチェック
            if (formData.personalMailAddress &&
                !/^[\x20-\x7e]*$/.test(formData.personalMailAddress.trim())) {
                InfoDialog.show('入力チェックエラー', '個人用メールアドレスは半角英数字記号で入力してください');
                return false;
            }
            var regularExpression = /^([a-zA-Z0-9-+._][a-zA-Z0-9-+_]+)+([-+._][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.([a-zA-Z]{2,})+$/;
            var unregistrableExplanation = '<br>※以下条件に該当するメールアドレスは登録できません。<br>' +
                '・@が使用されていないか、複数使用されている<br>' +
                '・「 - . _ + 」以外の記号が使用されている<br>' +
                '・スペースが使用されている<br>' +
                '・連続した2つ以上の「.」が使用されている<br>' +
                '・@の直前/直後に「.」が使用されている<br>' +
                '・@以降に「.」「-」以外の記号が使用されている';
            //庁内メールアドレスが有効であるかチェック
            if(formData.officialMailAddress && !regularExpression.test(formData.officialMailAddress.trim())){
                    InfoDialog.show('入力チェックエラー', '有効な庁内メールアドレスを入力してください。<br>' + unregistrableExplanation);
                    return false;
            }
            //個人用メールアドレスが有効であるかチェック
            if(formData.personalMailAddress && !regularExpression.test(formData.personalMailAddress.trim())){
                    InfoDialog.show('入力チェックエラー', '有効な個人用メールアドレスを入力してください。<br>' + unregistrableExplanation);
                    return false;
            }
            // 庁内メールアドレスと個人用メールアドレスが一致しない（異なるアドレスである）ことをチェック
            // 双方に設定値がある場合
            if (((formData.officialMailAddress && formData.personalMailAddress) &&
                (formData.officialMailAddress.trim() === formData.personalMailAddress.trim())) ||
                // 庁内メールアドレスが非活性、かつ個人用メールアドレスが設定されている場合
                (((!formData.officialMailAddress && formData.personalMailAddress) &&
                (this._officialMailAddress === formData.personalMailAddress.trim()))) ||
                // 庁内メールアドレスが設定され、かつ個人用メールアドレスが非活性の場合
                (((formData.officialMailAddress && !formData.personalMailAddress) &&
                (formData.officialMailAddress.trim() === this._personalMailAddress)))){
                    InfoDialog.show('入力チェックエラー', 
                    '庁内メールアドレスと個人用メールアドレスに同じアドレスは設定できません。<br>一方を異なるメールアドレスに変更してください。');
                    return false;
            }
            // hokkaidoUserIdが半角英数字かチェック
            // if (!/^[0-9a-zA-Z]*$/.test(formData.hokkaidoUserId)) {
            //     InfoDialog.show('入力チェックエラー', '北海道ユーザーIDは半角英数字で入力してください');
            //     return false;
            // }
            // if (formData.hokkaidoPassword !== '' && !this.isValidPassword(formData.hokkaidoPassword)) {
            //     InfoDialog.show('入力チェック', '入力された北海道パスワードが規則を満たしません。半角の英数および記号で８文字以上、２０文字以内で入力ください');
            //     return false;
            // }
            // govUserIdが半角英数字かチェック
            // if (!/^[0-9a-zA-Z]*$/.test(formData.govUserId)) {
            //     InfoDialog.show('入力チェックエラー', '内閣府ユーザーIDは半角英数字で入力してください');
            //     return false;
            // }
            // if (formData.govPassword !== '' && !this.isValidPassword(formData.govPassword)) {
            //     InfoDialog.show('入力チェック', '入力された内閣府パスワードが規則を満たしません。半角の英数および記号で８文字以上、２０文字以内で入力ください');
            //     return false;
            // }
            var lat = formData.latitude;
            var lng = formData.longitude;
            if(lat) {
                if(34 > lat || lat > 35) {
                    InfoDialog.show('入力チェック','大阪市内の緯度を入力ください');
                    return false;
                }
                var latnum = String(lat).split('.');
                var latlength =  (latnum[1]) ? latnum[1].length : 0;
                if(latlength < 1) {
                    InfoDialog.show('入力チェック','緯度は少数点で入力ください');
                    return false;
                }
                else if(latlength > 15) {
                    InfoDialog.show('入力チェック','緯度は少数第十五位以下で入力ください');
                    return false;
                }
            }
            if(lng) {
                if(135 > lng || lng > 136) {
                    InfoDialog.show('入力チェック','大阪市内の経度を入力ください');
                    return false;
                }
                var lngnum = String(lng).split('.');
                var lnglength =  (lngnum[1]) ? lngnum[1].length : 0;
                if(lnglength < 1) {
                    InfoDialog.show('入力チェック','経度は少数点で入力ください');
                    return false;
                }
                else if(lnglength > 15) {
                    InfoDialog.show('入力チェック','経度は少数第十五位以下で入力ください');
                    return false;
                }
            }
            return true;
        },

        /**
         * パスワードの妥当性を検証する。
         */
        isValidPassword: function(userPassword) {
            // 長さ
            if (userPassword.length < 8 || userPassword.length > 20) {
                return false;
            }

            // アルファベットが含まれているか
            if (/[^\x21-\x7e]/.test(userPassword)) {
                return false;
            }

            return true;
        },
		
        /**
         * 所属よりも上位の組織を検索する権限があるか検証する。
         */
		isValidOrg: function() {
            var formData = this.form.get('value');
			var role = UserInfo.getRoleCd();
//			if (role !== ACL.ADMIN_USER) {
//				if (UserInfo.getOrganization().sectCd !== null){
//				   this._authorized = formData.organization.substr(0,1) === 'D' ?  '1' :
//									  '0';
//				}else if (UserInfo.getOrganization().unitCd !== null){
//						 this._authorized = formData.organization.substr(0,1) === 'D' ?  '1' :
//											formData.organization.substr(0,1) === 'S' ?  '1' :
//											'0';
//				}
			if (role !== ACL.ADMIN_USER) {
				if (UserInfo.getOrganization().unitCd !== null){
					this._authorized = formData.organization.substr(0,1) === 'D' ?  '1' :
						formData.organization.substr(0,1) === 'S' ?  '1' :
							'0';
				}else if (UserInfo.getOrganization().sectCd !== null){
					this._authorized = formData.organization.substr(0,1) === 'D' ?  '1' :
						'0';
				}
				if (this._authorized === '1'){
					InfoDialog.show('入力チェック', '所属よりも上位の組織は選択できません');
					return false;
				}
			}
			return true;
		},

        // アカウント種類のセレクトボックスの値が変更された場合の処理
        onRoleCdSelectChanged: function(roleCd) {
            var canRefresh = this.organizationSelector.canRefresh();
            switch (roleCd.substr(0,3)) {
            case 'R01':
                // システム管理者、全体管理者、市本部管理者、所属管理者
            case 'R02':
                // 本部ユーザ処理
                if (canRefresh) {
                    this.organizationSelector.stopRefresh();
                }
                domStyle.set(this.municipalityCdBlock, 'display', 'none');
                this.emergencyOrganizationName.style.display = 'none';
                domStyle.set(this.emergencyOrgCdBlock, 'display', '');
                this.organizationSelector.set('noAllButton', false);
                this.officialPositionSelector.set('noAllButton', true);
                this.officialPositionName.set('disabled', true);
                this.supportStationSelector.set('noAllButton', true);
                this.emergencyOrganization.set('noAllButton', true);
                this.mobilizationSection.set('disabled', true);
                this.mobilizationType.set('disabled', true);
                this.officialMailAddress.set('disabled', true);
                this.personalMailAddress.set('disabled', true);

                // システム管理者、全体管理者の場合、局変更が可能
                if(UserInfo.getRoleCd() === ACL.ADMIN_USER ||UserInfo.getRoleCd() === ACL.SYSMGR_USER){
                    this.stationSelector.set('noAllButton', false);
                }else{
                    this.stationSelector.set('noAllButton', true);
                }
                if (canRefresh) {
                    this.organizationSelector.restoreRefresh();
                }
                break;
            case 'R03':
                // 一般職員、市職員以外のユーザ処理
                if (canRefresh) {
                    this.organizationSelector.stopRefresh();
                }
                domStyle.set(this.municipalityCdBlock, 'display', 'none');
                // 操作対象が一般職員ユーザおよび応援職員・自主防災・福祉避難所・施設管理者・下水処理事業者・
                // 道路管理事業者の場合
                if(roleCd === ACL.IPPAN_USER || this._acceptableUser.includes(roleCd)){
                    // 緊急時参集所属のセレクタを表示
                    this.emergencyOrganizationName.style.display = 'none';
                    domStyle.set(this.emergencyOrgCdBlock, 'display', '');
                    
                    this.stationSelector.set('noAllButton', true);
                    this.organizationSelector.set('noAllButton', false);
                    this.officialPositionSelector.set('noAllButton', false);
                    this.officialPositionName.set('disabled', false);
                    this.supportStationSelector.set('noAllButton', true);
                    this.emergencyOrganization.set('noAllButton', false);
                    this.mobilizationSection.set('disabled', false);
                    this.mobilizationType.set('disabled', false);
                    this.account.set('disabled', false);
                    this.userName.set('disabled', false);
                    this.userNameKana.set('disabled', false);
                    this.officialMailAddress.set('disabled', true);
                    this.personalMailAddress.set('disabled', true);

                    if(this.userId){ 
                        if(UserInfo.getId() === this.userId.innerHTML){
                            // ログインユーザが自身の場合、属性を上書き
                            this.organizationSelector.set('noAllButton', true);
                            this.officialPositionSelector.set('noAllButton', true);
                            this.officialPositionName.set('disabled', true);
                            this.supportStationSelector.set('noAllButton', true);
                            this.emergencyOrganization.set('noAllButton', true);
                            this.mobilizationSection.set('disabled', true);
                            this.mobilizationType.set('disabled', true);
                            this.account.set('disabled', true);
                            this.userName.set('disabled', true);
                            this.userNameKana.set('disabled', true);
                            this.officialMailAddress.set('disabled', true);
                            this.personalMailAddress.set('disabled', false);
                            this.note.set('disabled', true);
                        }
                    }
                    if (UserInfo.getRoleCd() === ACL.ADMIN_USER || UserInfo.getRoleCd() === ACL.SYSMGR_USER) {
                        if(roleCd === ACL.IPPAN_USER){
                            // ログインユーザがシステム管理者、全体管理者の場合、局選択可能
                            this.stationSelector.set('noAllButton', false);
                            this.supportStationSelector.set('noAllButton', false);
                            this.officialMailAddress.set('disabled', false);
                            this.personalMailAddress.set('disabled', false); 
                        } else {
                            // ログインユーザがシステム管理者、全体管理者の場合、かつ応援職員・自主防災・福祉避難所・施設管理者・下水処理事業者・
                            // 道路管理事業者の場合以外を選択した場合
                            // 緊急時参集所属は直接入力
                            this.stationSelector.set('noAllButton', false);
                            this.personalMailAddress.set('disabled', false); 
                            this.officialPositionSelector.set('noAllButton', true);
                            this.officialPositionName.set('disabled', true);
                            this.emergencyOrganization.set('noAllButton', true);
                            this.emergencyOrganizationName.style.display = '';
                            domStyle.set(this.emergencyOrgCdBlock, 'display', 'none');  
                            this.mobilizationSection.set('disabled', true);
                            this.mobilizationType.set('disabled', true);

                        }
                    } else if(UserInfo.getRoleCd() === ACL.XXMGR_USER && (roleCd !== ACL.IPPAN_USER)){
                        // ログインユーザが所属管理者かつ応援職員・自主防災・福祉避難所・施設管理者・下水処理事業者・
                        // 道路管理事業者ユーザの場合を選択した場合
                        // 緊急時参集所属は直接入力
                        this.emergencyOrganizationName.style.display = '';
                        domStyle.set(this.emergencyOrgCdBlock, 'display', 'none');  
	
                        this.stationSelector.set('noAllButton', true);
                        this.organizationSelector.set('noAllButton', false);
                        this.officialPositionSelector.set('noAllButton', true);
                        this.officialPositionName.set('disabled', true);
                        this.supportStationSelector.set('noAllButton', true);
                        this.mobilizationSection.set('disabled', true);
                        this.mobilizationType.set('disabled', true);
		
                        this.officialMailAddress.set('disabled', true);
                        this.personalMailAddress.set('disabled', true);
					}
                }else{
                    if (this.municipalityCd.get('value') === config.municInfo.cityMunicCd) {
                        this.municipalityCd.set('value', '');
                    }
                    // 市職員以外（一般職員ユーザ以外）の場合、緊急時参集所属は直接入力
                    this.emergencyOrganizationName.style.display = '';
                    domStyle.set(this.emergencyOrgCdBlock, 'display', 'none');  

                    this.stationSelector.set('noAllButton', true);
                    this.organizationSelector.set('noAllButton', false);
                    this.officialPositionSelector.set('noAllButton', true);
                    this.officialPositionName.set('disabled', true);
                    this.supportStationSelector.set('noAllButton', true);
                    this.mobilizationSection.set('disabled', true);
                    this.mobilizationType.set('disabled', true);
    
                    this.officialMailAddress.set('disabled', true);
                    this.personalMailAddress.set('disabled', true);
                }
                if (canRefresh) {
                    this.organizationSelector.restoreRefresh();
                }
                break;
            case 'R04':
                // 区ユーザ処理
                if (canRefresh) {
                    this.organizationSelector.stopRefresh();
                }
                // this.municipalityCd.set('value', '');
                // domStyle.set(this.municipalityCdBlock, 'display', '');
                // this.regionCd.set('value', '');
                // domStyle.set(this.regionCdBlock, 'display', 'none');
                if (canRefresh) {
                    this.organizationSelector.restoreRefresh();
                }
                break;
            default:
                break;
            }

            if(UserInfo.getRoleCd() === ACL.ADMIN_USER ||UserInfo.getRoleCd() === ACL.SYSMGR_USER){
                this.stationSelector.set('noAllButton', false);
            }
        },

        // 市町村セレクタの値が変更するときの動作
        onMunicipalityCdChanged: function(municipalityCd) {
            this.organizationSelector.setMunicipalityCd(municipalityCd.value);
        },

        // 振興局セレクタの値が変更するときの動作
        onRegionCdChanged: function(regionCd) {
            this.organizationSelector.setRegionCd(regionCd.value);
        },

        // 組織カテゴリセレクタの値が変更するときの動作
        onDeptCdChanged: function(deptCd) {
            if (!deptCd.value) {
                this.organizationSelector.set('value', '');
            } else {
                this.organizationSelector.setDeptCd(deptCd.value);
                this.organizationSelector.set('value', 'D' + deptCd.value);
            }
        }
    });
});
