define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--prefsitesending',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            helper.column('level', 'レベル', {
                formatMap: {
                    '1': '一般（お知らせ）',
                    '2': '通知（お知らせ）',
                    '3': '注意（お知らせ）',
                    '4': '重要（お知らせ）',
                    '5': '緊急'
                },sortable: false
            }),
            {field: 'subject', label: '件名', sortable: false },
            helper.column('bodyText', '本文', {sortable: false}),
            // helper.column('organizationName', '所轄組織', {sortable: false}),
            // {field: 'organizationName', label: '所轄組織',
            //  formatter: lang.hitch(this, function(item){
            //      if(!item) {
            //          return '-';
            //      }
            //      return item;
            //  })
            // },
            helper.column('municipalityName', '対象地域', {sortable: false}),
            {field: 'pubStartTimestamp', label: '掲載日時',
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            {field: 'pubEndTimestamp', label: '掲載期限',
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            {field: 'updTimestamp', label: '更新日時',
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            {field: 'registrant', label: '登録ユーザ',
                formatter: lang.hitch(this, function(item, news){
                    if (news.emergencyNewsType !== '900') {
                        return '自動';
                    }
                    return item;
                })
            },
            helper.column('signiture', '備考', {sortable: false}),
        ]
    });
});
