/**
 * 人的被害一覧ダイアログ画面用モジュール。
 * @module app/damage/HumanDamageReportDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/DamagePublicStatusDialog.html',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'dijit/form/CheckBox',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/RadioGroup',
    'idis/view/form/Button'
], function (module, array, declare, lang, json, template,
    _PageBase, InfoDialog, Requester, Loader, DialogChain, UserInfo) {

    /**
     * 人的被害一覧ダイアログ
     * @class HumanDamageReportDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends app/view/Header~Header# */ {

            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'damageReportPublic-dialog',

            /**
             * コンストラクタ
             */
            constructor: function () {
                //this.inherited(arguments);
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
            },

            /**
             * DOM生成
             */
            buildRendering: function () {
                this.inherited(arguments);

            },

            /**
             * 画面生成完了
             */
            startup: function () {
                this.inherited(arguments);
            },
            /**
             * フォームデータ初期化
             * @param item 
             */
            initFrom: function (item) {
                
                // システム管理者(R01001 / R01011)と広報担当は登録ボタンを表示、それ以外は非表示                    
                if (UserInfo.getRoleCd() === 'R03202' || UserInfo.getRoleCd() === 'R01001' || UserInfo.getRoleCd() === 'R01011') {                    
                    this.registerPublicStatusButton.set('style', { display: '' });
                }

                this.form.reset();
                this.admNum.set('value', item.admNum);
                this.version.set('value', item.version);
                this.seqNum.set('value', item.seqNum);
                
                if(item.publicFlg === '1' ){
                    this.damagePublicRdo.set('checked', 'checked');
                    this.damageNonPublicRdo.set('checked', '');
                } else {
                    this.damagePublicRdo.set('checked', '');
                    this.damageNonPublicRdo.set('checked', 'checked');
                }
                var setPublicFlg = (item.publicFlg === '2' ||  item.publicFlg === '0') ? '1' : item.publicFlg;
                this.form.set('value', {publicFlg:setPublicFlg});
                this.damageNonPublicRdo.set('disabled', setPublicFlg !== '1');
                this.damagePublicRdo.set('disabled', setPublicFlg !== '1');
            },

            initContent:function (curItem, preItem) {
                // 被害による通行状況（確定報・公開報）
                // 被害種別を判定(道路)
                if( curItem.damageType === '09') {
                    this.fPublicTrafficStatus.hidden = false; 
                    // 公開報を含む被害報告ありフラグをチェック
                    if (preItem.containingPublicReportFlg === '1' ){
                        this.fTrafficStatus.hidden = false;
                        this.trafficStatus.set('value', curItem.trafficStatus);
                        this.publicTrafficStatus.set('value', preItem.publicTrafficStatus);
                        if(curItem.publicUpdFlg && curItem.publicUpdFlg === '1'){
                            // 更新フラグが立っていた場合は最新報の情報を設定
                            this.publicTrafficStatus.set('value', curItem.publicTrafficStatus);
                        }
                      
                    } else {               
                        // 公開設定が未確認かつ被害概要（公開用）の登録・編集が1度もない場合
                        // 確定報の通行状況を設定 
                        this.publicTrafficStatus.set('value', curItem.trafficStatus); 
                        
                        // 公開設定の更新フラグをチェック
                        if(curItem.publicUpdFlg && curItem.publicUpdFlg === '1'){
                            // 更新フラグが立っていた場合は最新報の情報を設定
                            this.publicTrafficStatus.set('value', curItem.publicTrafficStatus);
                        }

                    }
                } 
                // 被害概要（確定報・公開報）と 内部向け備考
                // 公開報を含む被害報告ありフラグをチェック
                if ( preItem.containingPublicReportFlg === '1' ){
                    this.fDamageContent.hidden = false;
                    this.damageContent.set('value', curItem.damageContent);
                    this.publicDamageContent.set('value', preItem.publicDamageContent);
                    this.internalComment.set('value', preItem.internalComment);
                    // 公開設定の更新フラグをチェック
                    if(curItem.publicUpdFlg && curItem.publicUpdFlg === '1'){
                        // 更新フラグが立っていた場合は最新報の情報を設定
                        this.publicDamageContent.set('value', curItem.publicDamageContent);
                        // 内部向け備考
                        this.internalComment.set('value', curItem.internalComment);
                    }
                    
                } else {
                    // 公開設定が未確認かつ被害概要（公開用）の登録・編集が1度もない場合
                    // 確定報の被害概要を設定  
                    this.publicDamageContent.set('value', curItem.damageContent);
                    // 内部向け備考
                    this.internalComment.set('value', preItem.internalComment);

                    // 公開設定の更新フラグをチェック
                    if(curItem.publicUpdFlg && curItem.publicUpdFlg === '1'){
                        // 更新フラグが立っていた場合は最新報の情報を設定
                        this.publicDamageContent.set('value', curItem.publicDamageContent);
                        this.internalComment.set('value', curItem.internalComment);
                    }
                }

            },

            onChangePublicFlg: function () {
                // 添付画像の公開設定：非活性、活性への切り替え
                this.damageNonPublicRdo.set('disabled', this.form.get('value').publicFlg !== '1');
                this.damagePublicRdo.set('disabled',this.form.get('value').publicFlg !== '1');
                if(this.form.get('value').publicFlg !== '1'){
                    // 公開設定：非表示時、添付画像の公開設定：全非公開設定
                    this.damageNonPublicRdo.set('value','1');
                }
            },

            /**
             * 登録ボタン実行
             */
            onSubmit: function () {
                var publicFlg = this.form.get('value').publicFlg;
                if (publicFlg === '1' && (this.damageNonPublicRdo.get('checked') === true || 
                                            this.damageNonPublicRdo.get('checked') === 'checked')) {
                    publicFlg = 2;
                }
                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) {
                    return false;
                }
                
                this.chain.confirm('全ての被害報の公開設定変更しますか？', lang.hitch(this, function (chain) {
                    chain.hide();

                    Loader.wait(Requester.post('/api/damageReports/updatePublicStatus?admNum=' + this.admNum.get('value') + 
                        '&seqNum='+ this.seqNum.get('value'),
                    {
                        data: json.stringify({
                             publicFlg: publicFlg, 
                             version: this.version.get('value') ,
                             publicDamageContent: this.publicDamageContent.get('value'),
                             publicTrafficStatus: this.publicTrafficStatus.get('value'),
                             internalComment: this.internalComment.get('value'),
                             publicUpdFlg: '1'
                            }),
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(function () {
                        console.debug('success register public damageReport');
                        chain.infoComplete(lang.hitch(this, function () {
                            chain.hide();
                            location.reload(false);
                        }));
                    }), function (err) {
                        // いずれかに失敗時
                        chain.infoError(err);
                    });
                }));
            },

        });
});
