/**
 * ポータル配信情報登録用ダイアログモジュール。
 * @module app/prefsitesending/PrefSiteSendingRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/PrefSiteSendingRegisterDialog.html',
    'dijit/registry',
    'idis/model/UserInfo',
    './_PrefSiteSendingDialogBase',
    '../config',
    // 以下、変数で受けないモジュール
    'app/view/form/CustomizableMunicipalitySelector',
], function(module, declare, template, registry, UserInfo, _PrefSiteSendingDialogBase, config) {
    /**
     * ポータル配信情報登録用ダイアログ
     * @class PrefSiteSendingDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PrefSiteSendingDialogBase, {
        // テンプレート文字列
        templateString: template,

        // 添付ファイル
        attachFileList: [],

        constructor: function() {
            this.inherited(arguments);
        },

        buildRendering: function() {
            var obj = registry.byId('outerRegisterSelectorDialog');
            if (obj) {
                registry.remove('outerRegisterSelectorDialog');
            }
            obj = registry.byId('registerSelectorDialog');
            if (obj) {
                registry.remove('registerSelectorDialog');
            }
            this.inherited(arguments);
        },

        /**
         * ダイアログの初期化
         */
        initDialog: function() {
            this.form.reset();
            this.clearPreview();
            // this.municipalityCd.set('value', UserInfo.getMunicipalityCd() || config.municInfo.prefMunicCd);
            this.municipalityCd.set('value', config.municInfo.cityMunicCd);
            // this.sendingSection.set(
            //     'value',  UserInfo.getLowestOrganizationCd()[0] + UserInfo.getLowestOrganizationCd());
        },

        /**
         * 新規登録
         */
        onSubmit: function() {
            try {
                console.debug('[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.attachFileList = this.attachFileList;

                this.emit('register', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * テンプレート選択ダイアログの表示
         */
        showTemplateDialog: function() {
            var selectorDialog = registry.byId('detailSelectorDialog');
            selectorDialog.registerObj = this;

            var innerDialog = this.dialog.getChildren()[0];
            innerDialog.resize();

            this.dialog.show();
        }
    });
});
